<template>
  <div>
    <div
      class="container bg-signet bg-contain bg-[right_-25vw_center] bg-no-repeat lg:bg-[length:50%_auto] lg:bg-center"
    >
      <div class="lg:relative lg:h-svh" ref="containerRef">
        <div
          class="lg:absolute lg:z-10 lg:flex lg:h-[calc(100svh-clamp(11rem,calc(6.009389671361502vw+9.591549295774648rem),16.802816901408452rem))] lg:w-vw-37.5-to-167.5 lg:flex-col lg:justify-end lg:pb-vw-1-to-11 lg:pr-[8.333%]"
        >
          <div
            class="mb-vw-1.5-to-14 mt-vw-11-to-12 flex pr-vw-7-to-9 max-lg:justify-end lg:mt-vw-1-to-12"
          >
            <IconComponent icon="rocket" class="size-vw-10-to-20 text-blue" />
          </div>
          <h2 class="text-balance text-vw-headline-level-1 max-lg:w-4/5">
            Softwarelösungen für Premium-Kundenservice im Maschinenbau.
          </h2>
          <div class="mt-vw-4-to-8 flex gap-vw-2-to-4">
            <button
              class="rounded-full focus:outline-none focus-visible:outline-current"
              @click="setIsOpen(true)"
              type="button"
            >
              <AppButton
                >Video<IconComponent icon="circle-arrow-right-filled"
              /></AppButton>
            </button>
            <button
              class="rounded-full focus:outline-none focus-visible:outline-current"
              @click.prevent="handleClickContactButton"
            >
              <AppButton
                >Kontakt<IconComponent icon="circle-arrow-down-filled"
              /></AppButton>
            </button>
          </div>
        </div>
        <div
          class="relative lg:sticky lg:top-vw-31-to-40 lg:ml-auto lg:mt-vw-31-to-40 lg:h-[calc(100svh-clamp(11rem,calc(6.009389671361502vw+9.591549295774648rem),16.802816901408452rem))] lg:w-vw-37.5-to-167.5"
          ref="mediaWrapperRef"
        >
          <AppVideo
            class="mt-vw-11-to-12 w-full rounded object-cover focus:outline-none focus-visible:outline-current max-lg:aspect-[320/294] lg:absolute lg:inset-0 lg:mt-0 lg:h-full"
            :width="1920"
            :height="1080"
            poster="/video/home-poster.webp"
            tabindex="-1"
          >
            <source data-src="/video/home.mp4" type="video/mp4" />
          </AppVideo>
          <button
            class="absolute inset-0 flex items-end justify-end rounded px-vw-4-to-8 py-vw-5-to-10 focus:outline-none focus-visible:outline-current"
            :class="{ 'lg:hidden': !state.animationDone }"
            @click="setIsOpen(true)"
            type="button"
          >
            <PlayButton></PlayButton>
          </button>
          <Dialog :open="isOpen" @close="setIsOpen">
            <DialogPanel
              class="fixed inset-0 z-50 flex h-full w-full items-center justify-center bg-black text-white"
            >
              <video
                ref="videoFullRef"
                poster="/video/home-full-poster.webp"
                class="focus:outline-none focus-visible:outline-current"
                playsinline
                disablepictureinpicture
                controls
                width="1920"
                height="1080"
              >
                <source src="/video/home-full.mp4" type="video/mp4" />
              </video>
              <button
                title="Schließen"
                class="absolute right-vw-4-to-8 top-vw-5-to-10 aspect-square rounded-full bg-dark-blue p-vw-3-to-4 focus:outline-none focus-visible:outline-current"
                @click="setIsOpen(false)"
              >
                <AppHeaderBurgerIcon :open="true" />
              </button>
            </DialogPanel>
          </Dialog>
          <AppSignet
            class="absolute bottom-vw-6-to-24 left-vw-6-to-14 size-vw-20-to-48 text-white"
          ></AppSignet>
        </div>
      </div>
    </div>
    <div
      class="grid md:container md:my-vw-80-to-111 md:grid-cols-12 md:gap-x-5 lg:mt-vw-24-to-96"
    >
      <div
        class="max-md:container md:col-span-5 md:place-self-center lg:col-span-4 lg:col-start-2"
      >
        <h3 class="text-balance text-vw-headline-level-2 max-md:mt-vw-14-to-16">
          Wir haben oder wir erstellen die richtige Software für Ihre
          individuellen Anforderungen.
        </h3>
        <p class="mt-vw-4-to-6 max-w-prose">
          Für exzellenten Kundenservice im Maschinen- und Anlagenbau ist
          smartsquare Ideengeber und engagierter Problemlöser. Wir sind Ihr
          Partner von der Analyse bis zur fertigen Softwarelösung – und darüber
          hinaus.
        </p>
        <div
          class="flex justify-end pr-vw-7-to-24 text-green md:pt-vw-1-to-10"
          aria-hidden="true"
        >
          <IconComponent icon="arrow" class="w-vw-14-to-28 md:hidden" />
          <IconComponent
            icon="arrow-desktop"
            class="w-vw-12-to-44 max-md:hidden"
          />
        </div>
      </div>
      <ParallaxNavPills></ParallaxNavPills>
    </div>
    <div class="container max-md:mt-vw-24-to-32">
      <div class="grid md:grid-cols-12 md:gap-x-5">
        <div class="md:col-span-9 md:whitespace-nowrap lg:col-span-8">
          <h2 class="text-balance text-vw-headline-teaser md:inline">
            Seien Sie sicher: Wir verstehen Maschinen- und Anlagenbau.
          </h2>
          <NuxtLink
            class="inline-flex rounded-full align-bottom text-vw-headline-teaser focus:outline-none focus-visible:outline-current max-md:mt-vw-6-to-7"
            to="/kontakt/"
            ><AppButton
              >Kontakt<IconComponent
                icon="circle-arrow-right-filled" /></AppButton
          ></NuxtLink>
        </div>
        <div class="hidden lg:col-span-2 lg:col-start-10 lg:block">
          <AppSignet class="w-full text-white"></AppSignet>
        </div>
      </div>
      <div class="mt-vw-10-to-20 space-y-lh max-md:max-w-prose lg:w-10/12">
        <ReadMore
          :tolerance="0"
          :lines-to-show-on-desktop="-1"
          :lines-to-show-on-mobile="5"
          has-two-columns
        >
          <p>
            Durch unsere langjährige Erfahrung im Maschinen- und Anlagenbau
            wissen wir, worauf es Ihnen in der Branche ankommt. Professionelle
            Unternehmenssoftware, mit der wir höchste Kundenzufriedenheit
            erreichen, erstellen wir seit 2011.
          </p>
          <p>
            Smartsquare ist Ideengeber und Umsetzer auf höchstem Niveau. Wir
            erstellen Ihre eigene, individuell auf Ihre Anforderungen
            zugeschnittene Software schnell & pragmatisch. Unser Angebot: Ihr
            erstes digitales Produkt können wir in 6 Wochen entwickeln.
          </p>
        </ReadMore>
      </div>
    </div>
    <ParallaxPills
      class="!mt-vw-16-to-48"
      :rows="[
        [
          {
            icon: 'idea',
            content: 'fertige Bausteine<br />und Anwendungen',
            bgColor: 'bg-green',
          },
          {
            icon: 'rocket',
            content: 'Planung, Program-<br />mierung und Betrieb',
            bgColor: 'bg-blue',
          },
          {
            icon: 'speed',
            content: 'Digitale Produkte<br />in 6 Wochen',
            bgColor: 'bg-dark-blue',
          },
        ],
        [
          {
            icon: 'touch',
            content: 'Kundennutzen<br />immer im Fokus',
            bgColor: 'bg-blue',
          },
          {
            icon: 'fingerprint',
            content: 'maßgeschneiderte<br />Softwarelösungen',
            bgColor: 'bg-dark-blue',
          },
          {
            icon: 'key',
            content: 'keine SaaS-<br />Gebühren oder<br />Abo-Modelle',
            bgColor: 'bg-green',
          },
        ],
      ]"
    ></ParallaxPills>
    <div
      class="bg-signet bg-[length:50%_auto] bg-[left_-15vw_bottom_2rem] bg-no-repeat pb-vw-32-to-48 md:bg-[length:30%_auto] md:bg-[left_-8vw_bottom_4rem] xl:bg-[length:20%_auto] xl:bg-[left_-5vw_bottom_6rem] 3xl:overflow-hidden 3xl:bg-[length:12%_auto] 3xl:bg-[left_calc(50%-58rem)_bottom_7rem]"
    >
      <div class="container mt-vw-24-to-48">
        <h2 class="text-balance text-vw-headline-level-1 lg:w-9/12">
          Machen Sie sich anhand
          <em class="whitespace-nowrap">konkreter Beispiele</em> ein Bild
          unserer Leistungen in verschiedenen Geschäftsbereichen.
        </h2>
        <div
          class="mt-vw-12-to-36 rounded bg-white/30 px-vw-7-to-28 py-vw-8-to-32 md:mt-vw-10-to-64 md:grid md:grid-cols-12 md:gap-x-5 md:pb-vw-12-to-14"
        >
          <div class="md:col-span-4 lg:col-span-3">
            <h3 class="text-vw-headline-level-2 2xl:w-11/12">
              Beispiele guter Praxis
            </h3>
            <NuxtLink
              class="mt-vw-4-to-8 inline-block rounded-full focus:outline-none focus-visible:outline-current"
              to="/referenz-projekte/"
              ><AppButton
                >Referenzen<IconComponent
                  icon="circle-arrow-right-filled" /></AppButton
            ></NuxtLink>
          </div>
          <ReferenceSwiper
            class="-mx-vw-14-to-38 mt-vw-3-to-12 md:col-span-8 md:col-start-5 md:-mt-vw-16-to-72 md:ml-0 lg:-ml-vw-4-to-12 3xl:-mr-[calc((100vw-1523px)/2)]"
            @close-lightbox="setLightboxContent($event)"
            :lightbox-content="state.lightboxContent"
            :lightbox-id="1"
          >
            <swiper-slide
              @click.prevent="
                setLightboxContent({
                  type: 'video',
                  src: '/img/referenz-projekte/Novoferm.mp4',
                  lightboxId: 1,

                  lightboxId: 1,
                })
              "
            >
              <AppVideo
                class="pointer-events-none my-vw-7-to-10 w-full rounded shadow-lg focus:outline-none focus-visible:outline-current md:shadow-none"
                :width="1920"
                :height="1080"
                poster="/img/referenz-projekte/Novoferm-poster.webp"
              >
                <source
                  data-src="/img/referenz-projekte/Novoferm.mp4"
                  type="video/mp4"
                />
              </AppVideo>
            </swiper-slide>
            <swiper-slide
              @click.prevent="
                setLightboxContent({
                  type: 'video',
                  src: '/img/referenz-projekte/POLYMA_fernueberwachung.mp4',
                  lightboxId: 1,
                })
              "
            >
              <AppVideo
                class="pointer-events-none my-vw-7-to-10 w-full rounded shadow-lg focus:outline-none focus-visible:outline-current md:shadow-none"
                :width="1280"
                :height="720"
                poster="/img/referenz-projekte/POLYMA_fernueberwachung-poster.webp"
              >
                <source
                  data-src="/img/referenz-projekte/POLYMA_fernueberwachung.mp4"
                  type="video/mp4"
                />
              </AppVideo>
            </swiper-slide>
            <swiper-slide
              @click.prevent="
                setLightboxContent({
                  type: 'video',
                  src: '/img/referenz-projekte/c-ware-AIR.mp4',
                  lightboxId: 1,
                })
              "
            >
              <AppVideo
                class="pointer-events-none my-vw-7-to-10 w-full rounded shadow-lg focus:outline-none focus-visible:outline-current md:shadow-none"
                :width="1920"
                :height="1080"
                poster="/img/referenz-projekte/c-ware-AIR-poster.webp"
              >
                <source
                  data-src="/img/referenz-projekte/c-ware-AIR.mp4"
                  type="video/mp4"
                />
              </AppVideo>
            </swiper-slide>
          </ReferenceSwiper>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="relative mb-vw-24-to-48">
        <NuxtImgWrapper
          src="/img/home/home-breaker.png"
          alt=""
          width="1829"
          height="1062"
          format="webp"
          class="w-full rounded object-cover sm:aspect-video"
        />
        <AppSignet
          class="absolute bottom-vw-6-to-24 left-vw-6-to-14 size-vw-20-to-48 text-white"
        ></AppSignet>
      </div>
    </div>
    <RevolverSwiper class="pb-vw-28-to-96" loop-slides>
      <template #header>
        <h2 class="text-vw-headline-level-1">
          Unsere Kunden<br />sprechen für uns
        </h2>
      </template>
      <swiper-slide class="pb-vw-32-to-52 pt-vw-6-to-44">
        <AppQuoteModule class="bg-blue">
          <template #person>
            <p class="font-semibold">Stephan Vogt</p>
            <p>Head of Project Management</p>
          </template>
          <template #personImage>
            <AppQuoteImage
              src="/img/home/testimonials/stephan-vogt.jpg"
              alt="Porträt Stephan Vogt"
              :width="1024"
              :height="1024"
            />
          </template>
          »Bei der Entwicklung von digitalen Produkten arbeiten wir gerne mit
          Teams von Smartsquare zusammen, deren zielstrebige und agile
          Arbeitsweise ich sehr schätze.«
        </AppQuoteModule>
      </swiper-slide>
      <swiper-slide class="pb-vw-32-to-52 pt-vw-6-to-44">
        <AppQuoteModule class="bg-white">
          <template #person>
            <p class="font-semibold">Michael Beckmann</p>
            <p>geschäftsf. Gesellschafter</p>
          </template>
          <template #personImage>
            <AppQuoteImage
              src="/img/home/testimonials/michael-beckmann.jpeg"
              alt="Porträt Michael Beckmann"
              :width="800"
              :height="800"
            />
          </template>
          »Besten Dank für den gestrigen Workshop. Die Atmosphäre war
          hervorragend und effektiver hätten wir die 8 Stunden kaum nutzen
          können. Unsere Erwartungen wurden mehr als erfüllt.«
        </AppQuoteModule>
      </swiper-slide>
      <swiper-slide class="pb-vw-32-to-52 pt-vw-6-to-44">
        <AppQuoteModule class="bg-green">
          <template #person>
            <p class="font-semibold">Katja Langenbach</p>
            <p>geschäftsf. Gesellschafterin</p>
          </template>
          <template #personImage>
            <AppQuoteImage
              src="/img/home/testimonials/katja-langenbach.jpeg"
              alt="Porträt Katja Langenbach"
              :width="400"
              :height="400"
            />
          </template>
          »Seit Jahren waren wir auf der Suche nach einer auf unsere Bedürfnisse
          angepassten Planungssoftware, aber leider vergeblich. Umso glücklicher
          sind wir, dass wir Smartsquare gefunden haben, die unsere
          Anforderungen verstanden und erstklassig umgesetzt haben.«
        </AppQuoteModule>
      </swiper-slide>
      <swiper-slide class="pb-vw-32-to-52 pt-vw-6-to-44">
        <AppQuoteModule class="bg-blue">
          <template #person>
            <p class="font-semibold">Michael Eikelmann</p>
            <p>geschäftsf. Gesellschafter</p>
          </template>
          <template #personImage>
            <AppQuoteImage
              src="/img/home/testimonials/michael-eikelmann.jpg"
              alt="Porträt Michael Eikelmann"
              :width="1020"
              :height="702"
            />
          </template>
          »Mit Smartsquare als Partner haben wir in nur 6 Wochen eine extrem
          leistungsfähige und auf unsere Bedürfnisse zugeschnittene
          IoT-Architektur auf die Beine gestellt. Auf Basis dieser soliden
          Grundlage widmen wir uns jetzt dem schrittweisen Ausbau der Plattform
          um neue Angebote für unsere Kunden.«
        </AppQuoteModule>
      </swiper-slide>
      <swiper-slide class="pb-vw-32-to-52 pt-vw-6-to-44">
        <AppQuoteModule class="bg-white">
          <template #person>
            <p class="font-semibold">Stefan Beckmann</p>
            <p>geschäftsf. Gesellschafter</p>
          </template>
          <template #personImage>
            <AppQuoteImage
              src="/img/home/testimonials/stefan-beckmann.jpeg"
              alt="Porträt Stefan Beckmann"
              :width="800"
              :height="800"
            />
          </template>
          »Auf das tolle Entwicklungs-Team bei Smartsquare war während des
          Aufbaus und der Markteinführung unserer Cloud-Plattform immer Verlass.
          In einem sportlichen Projekt haben sie unsere Kassensysteme mit
          IoT-Technologie vernetzt und digitale Mehrwerte für unsere Kunden
          implementiert.«
        </AppQuoteModule>
      </swiper-slide>
      <swiper-slide class="pb-vw-32-to-52 pt-vw-6-to-44">
        <AppQuoteModule class="bg-green">
          <template #person>
            <p class="font-semibold">Steffen Link</p>
            <p>Betriebsleiter</p>
          </template>
          <template #personImage>
            <AppQuoteImage
              src="/img/home/testimonials/steffen-link.jpg"
              alt="Porträt Steffen Link"
              :width="215"
              :height="215"
            />
          </template>
          »Wir sind mit dem schnellen Service und der hohen Qualität so
          zufrieden, dass wir die Zusammenarbeit mit Smartsquare nur
          weiterempfehlen können.«
        </AppQuoteModule>
      </swiper-slide>
    </RevolverSwiper>
  </div>
</template>

<script lang="ts" setup>
import { Dialog, DialogPanel, provideUseId } from '@headlessui/vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import type { LightboxContent } from '~/types/generated'

provideUseId(() => useId())

definePageMeta({
  description:
    'Wir sind Softwareentwickler und erstellen für Sie individuelle IoT-Plattformen, Web-Anwendungen und Apps.',
  title: 'Softwareentwicklung in Bielefeld',
  hasHero: true,
})

const mm = gsap.matchMedia()
const containerRef = ref()
const mediaWrapperRef = ref()
const videoFullRef = ref()
const state = reactive<{
  animationDone: boolean
  lightboxContent: LightboxContent | null
}>({
  animationDone: false,
  lightboxContent: null,
})
const isOpen = ref(false)

function setIsOpen(value: boolean) {
  isOpen.value = value
  if (value) {
    nextTick(() => {
      videoFullRef.value.play()
    })
  }
}

function setLightboxContent(el: LightboxContent) {
  state.lightboxContent = el
}

function handleClickContactButton() {
  document.getElementById('kontakt')?.scrollIntoView()
}

onMounted(() => {
  mm.add('(min-width: 1024px)', () => {
    gsap.to(mediaWrapperRef.value, {
      width: containerRef.value.offsetWidth,
      height: '90svh',
      top: '5svh',
      ease: 'none',
      scrollTrigger: {
        trigger: mediaWrapperRef.value,
        start: 'top top',
        end: 'bottom top',
        scrub: true,
        invalidateOnRefresh: true,
      },
      onComplete: () => {
        state.animationDone = true
        ScrollTrigger.sort()
        ScrollTrigger.refresh()
      },
    })
    gsap.to(containerRef.value, {
      height: '200svh',
      ease: 'none',
      scrollTrigger: {
        trigger: mediaWrapperRef.value,
        start: 'top top',
        end: 'bottom top',
        scrub: true,
        invalidateOnRefresh: true,
      },
      onComplete: () => {
        state.animationDone = true
        ScrollTrigger.sort()
        ScrollTrigger.refresh()
      },
    })
  })
})
</script>
