<template>
  <NuxtImgWrapper
    :alt="alt"
    :src="src"
    :width="width"
    :height="height"
    sizes="xs:240px"
    format="webp"
    class="size-vw-14-to-20 rounded-full object-cover"
  />
</template>

<script lang="ts" setup>
defineProps<{
  src: string
  alt: string
  width?: number
  height?: number
}>()
</script>
