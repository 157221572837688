<template>
  <blockquote
    class="flex h-full flex-col rounded pb-vw-8-to-10 pl-vw-7-to-10 pr-vw-4-to-6 pt-vw-4-to-6 text-vw-circular-slider"
  >
    <p class="mt-vw-4-to-6 pr-vw-3-to-4">
      <slot></slot>
    </p>
    <footer class="order-first flex items-center justify-between gap-vw-4-to-5">
      <div>
        <slot name="person"></slot>
      </div>
      <slot name="personImage"></slot>
    </footer>
  </blockquote>
</template>
