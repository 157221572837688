<template>
  <nav
    class="max-md:mt-vw-10-to-11 md:col-span-6 md:col-start-7 md:my-auto lg:col-span-5 lg:col-start-8 lg:-mb-vw-1-to-32 lg:mt-auto"
    aria-label="Softwarelösungen für Maschinenbau Navigation"
  >
    <ul
      class="flex items-center text-white max-md:flex-col max-md:overflow-x-hidden"
      ref="containerRef"
    >
      <li data-offset="25" class="relative w-vw-64-to-140 md:w-1/3">
        <div
          aria-hidden="true"
          class="pointer-events-none absolute inset-y-0 -left-full h-full w-full rounded-full bg-gradient-to-r from-transparent to-white/60 md:inset-x-0 md:-top-vw-48-to-100 md:bg-gradient-to-b"
        ></div>
        <NuxtLink
          class="group inline-block w-full rounded-full focus:outline-none focus-visible:-outline-offset-4 focus-visible:outline-current"
          to="/softwareloesungen-fuer-maschinenbau/fernueberwachung-und-monitoring/"
          ><AppPill class="h-vw-20-to-48 bg-blue" vertical-on-desktop
            ><IconComponent
              class="size-vw-6-to-14 flex-none text-dark-blue"
              icon="fernueberwachung" /><span class="whitespace-nowrap"
              >Fernüber<span class="max-md:hidden">-</span
              ><br class="max-md:hidden" />wachung <br class="md:hidden" />&
              <br class="max-md:hidden" />Monitoring</span
            ><IconComponent
              class="size-vw-6-to-14 flex-none transition-transform group-hover:translate-x-1 max-md:ml-auto md:mt-auto"
              icon="circle-arrow-right-filled" /></AppPill
        ></NuxtLink>
        <div
          aria-hidden="true"
          class="pointer-events-none absolute inset-y-0 -right-vw-20-to-48 size-vw-20-to-48 rounded-full bg-home-fernueberwachung-und-monitoring-desktop bg-cover bg-center bg-no-repeat md:inset-x-0 md:-bottom-vw-48-to-100 md:top-auto md:size-full"
        ></div>
      </li>
      <li data-offset="50" class="relative w-vw-64-to-140 md:w-1/3">
        <div
          aria-hidden="true"
          class="pointer-events-none absolute inset-y-0 -left-vw-20-to-48 size-vw-20-to-48 rounded-full bg-home-kundendienst-und-aftersales-desktop bg-cover bg-center bg-no-repeat md:inset-x-0 md:-top-vw-48-to-100 md:size-full"
        ></div>
        <NuxtLink
          class="group inline-block w-full rounded-full focus:outline-none focus-visible:-outline-offset-4 focus-visible:outline-current"
          to="/softwareloesungen-fuer-maschinenbau/kundendienst-und-aftersales/"
          ><AppPill class="h-vw-20-to-48 bg-green" vertical-on-desktop>
            <IconComponent
              class="size-vw-6-to-14 flex-none text-dark-blue"
              icon="kundendienst" /><span class="whitespace-nowrap"
              >Kunden<span class="max-md:hidden">-</span
              ><br class="max-md:hidden" />dienst &<br />Aftersales</span
            ><IconComponent
              class="size-vw-6-to-14 flex-none transition-transform group-hover:translate-x-1 max-md:ml-auto md:mt-auto"
              icon="circle-arrow-right-filled" /></AppPill
        ></NuxtLink>
        <div
          aria-hidden="true"
          class="pointer-events-none absolute inset-y-0 -right-full h-full w-full rounded-full bg-gradient-to-l from-transparent to-white/60 md:inset-x-0 md:-bottom-vw-48-to-100 md:top-auto md:bg-gradient-to-t"
        ></div>
      </li>
      <li data-offset="100" class="relative w-vw-64-to-140 md:w-1/3">
        <div
          aria-hidden="true"
          class="pointer-events-none absolute inset-y-0 -left-full h-full w-full rounded-full bg-gradient-to-r from-transparent to-white/60 md:inset-x-0 md:-top-vw-48-to-100 md:bg-gradient-to-b"
        ></div>
        <NuxtLink
          class="group inline-block w-full rounded-full focus:outline-none focus-visible:-outline-offset-4 focus-visible:outline-current"
          to="/softwareloesungen-fuer-maschinenbau/prozesse-und-daten/"
          ><AppPill class="h-vw-20-to-48 bg-dark-blue" vertical-on-desktop>
            <IconComponent
              class="size-vw-6-to-14 flex-none text-green"
              icon="dashboards" /><span class="whitespace-nowrap"
              >Prozesse &<br />Daten</span
            ><IconComponent
              class="size-vw-6-to-14 flex-none transition-transform group-hover:translate-x-1 max-md:ml-auto md:mt-auto"
              icon="circle-arrow-right-filled" /></AppPill
        ></NuxtLink>
        <div
          aria-hidden="true"
          class="pointer-events-none absolute inset-y-0 -right-vw-20-to-48 size-vw-20-to-48 rounded-full bg-home-prozesse-und-daten-desktop bg-cover bg-center bg-no-repeat md:inset-x-0 md:-bottom-vw-48-to-100 md:top-auto md:size-full"
        ></div>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
import { gsap } from 'gsap'
const containerRef = ref()
const mm = gsap.matchMedia()
onMounted(() => {
  mm.add('(max-width: 767px)', () => {
    parallaxAnimation(true)
  })
  mm.add('(min-width: 768px)', () => {
    parallaxAnimation(false)
  })
})

function parallaxAnimation(isMobile: boolean) {
  containerRef.value
    .querySelectorAll('li')
    .forEach((li: HTMLElement, index: number) => {
      gsap.fromTo(
        li,
        {
          transform: () => {
            if (index % 2 === 0) {
              return isMobile
                ? `translateX(${li.dataset.offset}%)`
                : `translateY(${li.dataset.offset}%)`
            } else {
              return isMobile
                ? `translateX(-${li.dataset.offset}%)`
                : `translateY(-${li.dataset.offset}%)`
            }
          },
        },
        {
          transform: () => {
            if (index % 2 === 0) {
              return isMobile
                ? `translateX(-${li.dataset.offset}%)`
                : `translateY(-${li.dataset.offset}%)`
            } else {
              return isMobile
                ? `translateX(${li.dataset.offset}%)`
                : `translateY(${li.dataset.offset}%)`
            }
          },
          ease: 'none',
          scrollTrigger: {
            trigger: containerRef.value,
            start: 'top bottom',
            end: 'bottom top',
            scrub: true,
            invalidateOnRefresh: true,
          },
        }
      )
    })
}
</script>
